import Enllac from 'components/3cat/Enllac';
import Image from 'next/image';
import { dataResources } from 'utils/constants';
import styles from './menu.module.scss';

const { basePath } = dataResources;

const imageLoader = ({ src }) => {
    return `${src}`;
};

export default function MenuEstatic(props) {
    return (
        <nav className={styles.barra}>
            <ul className={styles.menuBarra}>
                <li className={styles.separador}>
                    <Enllac href='https://www.3cat.cat/tv3/'>
                        <Image
                            className={styles.logoBarra}
                            loader={imageLoader}
                            unoptimized={true}
                            width={24}
                            height={20}
                            alt='TV3'
                            src={basePath + '/_resources/logos/tv3.svg?v=1.2502.4'}
                        />
                    </Enllac>
                </li>
                <li className={styles.separador}>
                    <Enllac href='https://www.3cat.cat/catradio/'>
                        <Image
                            className={styles.logoBarra}
                            loader={imageLoader}
                            unoptimized={true}
                            width={25}
                            height={22}
                            alt='Catalunya Ràdio'
                            src={basePath + '/_resources/logos/cr.svg?v=1.2502.4'}
                        />
                    </Enllac>
                </li>
                <li className={styles.separador}>
                    <Enllac href='https://www.3cat.cat/tv3/sx3/'>
                        <Image
                            className={styles.logoBarra}
                            loader={imageLoader}
                            unoptimized={true}
                            width={32}
                            height={16}
                            alt='SX3'
                            src={basePath + '/_resources/logos/sx3.svg?v=1.2502.4'}
                        />
                    </Enllac>
                </li>
                <li className={styles.separador}>
                    <Enllac href='https://www.3cat.cat/3cat/eva/'>
                        <Image
                            className={styles.logoBarra}
                            src={basePath + '/_resources/logos/eva.svg?v=1.2502.4'}
                            loader={imageLoader}
                            unoptimized={true}
                            width='34'
                            height='15'
                            alt='EVA'
                        ></Image>
                    </Enllac>
                </li>
                <li>
                    <Enllac href='https://www.3cat.cat/324/'>Notícies</Enllac>
                </li>
                <li className={styles.separador}>
                    <Enllac href='https://www.3cat.cat/esport3/'>Esports</Enllac>
                </li>
                <li className={styles.textLight}>
                    <Enllac href='https://botiga.3cat.cat/'>Botiga</Enllac>
                </li>
                <li className={styles.textLight}>
                    <Enllac href='https://www.3cat.cat/corporatiu/ca/el-grup/'>Corporatiu</Enllac>
                </li>
            </ul>
        </nav>
    );
}
